/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "duurzaam/duurzaam-11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "duurzaam/duurzaam-11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(relativePath: { eq: "duurzaam/duurzaam.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `Energie besparen met duurzame oplossingen`,
  textWithLink: () => (
    <>
      <p>
        De energieprijzen blijven ieder jaar stijgen wat resulteert in een
        steeds hogere energierekening.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/duurzaam'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Duurzaam = ({ data, path, location }) => {
  const seo = {
    title: `Duurzaam`,
    description: `Draag je steentje bij en wordt duurzaam met de kwaliteit die E S I - Installatietechniek B.V. levert.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'E S I - Duurzaam',
    inner:
      'Vervolg energie besparen, aan eind van tekstblok nog volgende tekst toevoegen: Kijk verder op onze site voor de diverse duurzame oplossingen die E S I – Installatietechniek B.V. u kan bieden.',
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Duurzaam met E S I`,
    pitchLine: `Draag je steentje bij en wordt duurzaam met de kwaliteit die E S I - Installatietechniek B.V. levert.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Duurzaam.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Duurzaam;
